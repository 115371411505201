<template>
  <div class="MyPartner">
    <div class="myPartnerTitle">{{ $t("lang.SText98") }}</div>
    <div class="search">
      <div class="radio">
        <el-radio-group v-model="queryForm.state" @change="query">
          <el-radio label="0"> {{ $t("lang.SText99") }} </el-radio>
          <el-radio label="1"> {{ $t("lang.SText100") }} </el-radio>
          <el-radio label="2"> {{ $t("lang.Detached") }} </el-radio>
        </el-radio-group>
      </div>
      <dw-button
        @click="newPartner"
        class="right-button"
        type="addbutton"
        isAdd
        >{{ $t("lang.SText102") }}</dw-button
      >
    </div>
    <dwlist
      :url="source.partner_api.listData"
      :query-params="queryForm"
      ref="partnerList"
    >
      <el-table-column prop="type" label="" width="70px">
        <template slot-scope="scope">
          <div class="userHeadIcon" v-show="!scope.row.photo">
            <svg-icon icon-class="userHead"></svg-icon>
          </div>
          <div class="userHeadIcon" v-if="scope.row.photo">
            <el-image :src="scope.row.photo"></el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" :label="$t('lang.SText103')">
        <template slot-scope="scope">
          <div class="name">{{ scope.row.name }}</div>
          <div class="email">{{ scope.row.accountId }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="createDate" :label="$t('lang.SText104')">
      </el-table-column>
      <el-table-column prop="Role" :label="$t('lang.SText105')"
        ><template slot-scope="scope">
          <div class="RoleRight">
            <div class="name">
              <div>{{ scope.row.role }}</div>
            </div>
            <div
              class="signOut-icon1"
              :class="{ 'signOut-icon': scope.row.signOut }"
              @mouseover="signOutHover(scope.row)"
              @mouseleave="signOutHover(scope.row, 1)"
            >
              ···
            </div>
            <div
              v-show="scope.row.signOut"
              class="signOutHover"
              @mouseover="signOutHover(scope.row)"
              @mouseleave="signOutHover(scope.row, 1)"
            >
              <div class="list-button">
                <div
                  class="list-button-item"
                  @click="CancelInvitation(scope.row)"
                  v-show="scope.row.state == '0'"
                >
                  {{ $t("lang.SText106") }}
                </div>
                <span v-show="scope.row.state == '0'">|</span>
                <div
                  class="list-button-item"
                  @click="newPartner(scope.row)"
                  v-if="scope.row.state == '2'"
                >
                  {{ $t("lang.SText107") }}
                </div>
                <span v-show="scope.row.state == '2'">|</span>
                <div class="list-button-item" @click="editPartner(scope.row)">
                  {{ $t("lang.QText77") }}
                </div>
                <span v-show="scope.row.state == '1'">|</span>
                <div
                  class="list-button-item"
                  @click="clickDetachPartner(scope.row)"
                  v-show="scope.row.state == '1'"
                >
                  {{ $t("lang.Detach") }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
    </dwlist>
    <el-dialog
      :visible.sync="sendFlag"
      width="70%"
      v-loading="sendLoading"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="dialog-title1">
        <div class="left">
          <svg-icon icon-class="userHead" class="svg-icon"></svg-icon>
          <span v-if="sendFlag && !editFlag">{{ $t("lang.SText108") }}</span>
          <span v-if="editFlag">{{ $t("lang.SText109") }}</span>
        </div>
        <div class="close" @click="closeDialog()">
          <svg-icon class="close-icon" icon-class="close"></svg-icon>
        </div>
      </div>
      <el-form class="form" ref="partnerForm" :rules="rules" :model="form">
        <div class="form-title">{{ $t("lang.SText110") }}</div>
        <el-form-item
          class="form-item"
          prop="name"
          :label="$t('lang.SText111')"
          :rules="[
            {
              required: true,
              message: $t('lang.NText116'),
            },
          ]"
        >
          <el-input v-model="form.name"> </el-input>
        </el-form-item>
        <el-form-item
          class="form-item"
          prop="accountId"
          :label="$t('lang.SText112')"
          :rules="[
            {
              required: true,
              message: $t('lang.NText117'),
            },
          ]"
        >
          <el-input v-model="form.accountId" :disabled="editFlag || detachFlag">
          </el-input>
        </el-form-item>
        <el-form-item
          class="form-item"
          prop="role"
          :label="$t('lang.SText113')"
          :rules="[
            {
              required: true,
              message: $t('lang.NText118'),
              trigger: 'change',
            },
          ]"
        >
          <el-checkbox-group
            v-model="form.role"
            style="display: flex"
            :disabled="editFlag"
          >
            <el-checkbox label="Shipper"></el-checkbox>
            <el-checkbox label="Consignee"></el-checkbox>
            <el-checkbox label="Others"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <div class="form-button">
          <el-button
            class="send"
            type="primary"
            v-if="sendFlag"
            @click="sendInvitation()"
          >
            <span v-if="!editFlag">{{ $t("lang.SText114") }}</span
            ><span v-if="editFlag">{{ $t("lang.SText13") }}</span>
          </el-button>

          <el-button type="info" @click="closeDialog()">{{
            $t("lang.QText79")
          }}</el-button>
        </div>
      </el-form>
    </el-dialog>

    <dw-check-box
      :showCheckBox="cancelFlag"
      @Cancel="cancelFlag = false"
      @Save="deleteCompany"
      :primaryButton="$t('lang.yes')"
      :CancelButton="$t('lang.QText79')"
    >
      <span v-if="$i18n.locale == 'zh_cn'"
        >您想取消对“{{ form.name || null }}”的邀请吗 ?</span
      >
      <span v-else
        >Do you want to cancel the invitation of “{{ form.name || null }}”
        ?</span
      >
    </dw-check-box>
    <dw-check-box
      :showCheckBox="detachFlag"
      @Cancel="detachFlag = false"
      @Save="detachPartner"
      :primaryButton="$t('lang.yes')"
      :CancelButton="$t('lang.QText79')"
      :loading="detachLoading"
    >
      <span v-if="$i18n.locale == 'zh_cn'"
        >您想解除和“{{ detachForm.name || null }}”的关系吗 ?</span
      >
      <span v-else
        >Are you sure to detach the partner relationship with “{{
          detachForm.name || null
        }}” ?</span
      >
    </dw-check-box>
  </div>
</template>

<script>
import dwlist from "../../../components/dwList/dwlist.vue";
import test from "../../../test/test";
import editInfo from "../Account/info.vue";
import DwCheckBox from "../../../components/dwCheckBox/dwCheckBox.vue";
import Template from "../../Booking/template.vue";
export default {
  components: {
    dwlist,
    editInfo,
    DwCheckBox,
  },
  data() {
    return {
      staffData: test.staffData,
      radio: null,
      sendFlag: false,
      editFlag: false,
      cancelFlag: false,
      form: {
        role: [],
      },
      queryForm: {
        state: "0",
      },
      rules: {},
      sendLoading: false,
      detachForm: {},
      detachFlag: false,
      detachLoading: false,
    };
  },
  created() {
    this.$session("userInfo").get((value) => {
      this.queryForm.userId = value.id;
    });
  },
  methods: {
    clearValidate() {
      if (this.$refs["partnerForm"]) {
        // 延时执行
        this.$nextTick(function () {
          this.$refs["partnerForm"].clearValidate();
        });
      }
    },
    query() {
      this.$refs.partnerList.query();
    },
    signOutHover(data, type) {
      if (type) {
        this.$set(data, "signOut", false);
        return;
      }
      this.$set(data, "signOut", true);
    },
    editPartner(data) {
      console.log(data);
      this.sendFlag = true;
      this.editFlag = true;
      this.form = JSON.parse(JSON.stringify(data));
      this.form.role = data.role.split(",");
      console.log(this.form);
      this.clearValidate();
      // this.$set(this.form, "name", data.partnerName);
    },
    closeDialog() {
      this.sendFlag = false;
      this.editFlag = false;
    },
    CancelInvitation(data) {
      this.form = data;
      this.cancelFlag = true;
    },
    deleteCompany() {
      let that = this;
      that.sendLoading = true;
      that.$ajax
        .cPost(that.source.partner_api.cancelInvitation, that.form)
        .then((res) => {
          if (res.data.result == "false") {
            that.$notify.error({
              title: res.data.message,
              offset: 100,
            });
          } else {
            that.$notify.success({
              title: res.data.message,
              offset: 100,
            });
            this.cancelFlag = false;
            this.$notify({
              title: this.$t("lang.SText186"),
              type: "success",
              offset: 100,
              duration: 3000,
            });
            that.query();
          }
          that.sendLoading = false;
        });
    },
    newPartner(data) {
      this.form = { role: [] };
      if (data) {
        this.form = JSON.parse(JSON.stringify(data));
        this.form.role = data.role.split(",");
      }
      this.sendFlag = true;
      this.editFlag = false;
      this.clearValidate();
    },
    sendInvitation() {
      let that = this;
      that.$refs.partnerForm.validate((valid) => {
        if (valid) {
          that.sendLoading = true;
          let url = "";
          if (that.editFlag) {
            url = that.source.partner_api.update;
          } else {
            url = that.source.partner_api.sendInvitation;
          }
          let data = JSON.parse(JSON.stringify(that.form));
          data.role = data.role.toString();
          that.$ajax.cPost(url, data).then((res) => {
            if (res.data.result == "false") {
              that.$notify.error({
                title: res.data.message,
                offset: 100,
              });
            } else {
              that.$notify.success({
                title: res.data.message,
                offset: 100,
              });
              that.editFlag = false;
              that.sendFlag = false;
              that.query();
            }
            that.sendLoading = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    clickDetachPartner(data) {
      this.detachForm = data;
      this.detachFlag = true;
    },
    detachPartner() {
      let that = this;
      that.detachLoading = true;
      let data = {
        id: this.detachForm.id,
        role: this.detachForm.role,
        accountId: this.detachForm.accountId,
      };
      that.$ajax
        .cPost(that.source.partner_api.removePartner, data)
        .then((res) => {
          if (res.data.result == "false") {
            that.$notify.error({
              title: res.data.message,
              offset: 100,
            });
          } else {
            that.$notify.success({
              title: res.data.message,
              offset: 100,
            });
            this.detachFlag = false;
            // this.$notify({
            //   title: this.$t("lang.SText186"),
            //   type: "success",
            //   offset: 100,
            //   duration: 3000,
            // });
            that.query();
          }
          that.detachLoading = false;
        });
    },
  },
  created() {},
};
</script>

<style lang="scss">
@import "../../../assets/base.scss";
.MyPartner {
  width: 900px;
  margin: auto;
  min-height: 600px;
  .el-dialog {
    margin-top: 0 !important;
    height: 100%;
    margin: 0 auto;
    .el-dialog__header {
      padding: 0;
      display: none;
    }

    .el-dialog__body {
      padding: 0;
      height: 100%;
    }
  }
  .editInfo {
    padding: 30px 120px;
  }
  .myPartnerTitle {
    text-align: left;
    font-size: 30px;
    color: $mainColor;
    margin: 10px 0 40px 0;
  }
  .search {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .radio {
    padding: 20px 0;
  }
  .newPartner {
    font-size: 18px;
    height: 60px;
    width: 180px;
    line-height: 60px;
  }
  .userHeadIcon {
    height: 48px;
    width: 48px;
    color: $fontColor2;
    border: 1px solid $borderColor1;
    border-radius: 24px;
    overflow: hidden;
    .svg-icon {
      height: 30px;
      width: 30px;
      padding-top: 6px;
      padding-left: 8px;
      color: $fontColor2;
    }
  }
  .RoleRight {
    display: flex;
    justify-content: space-between;
  }
  .signOut-icon1 {
    color: $fontColor2;
    font-size: 40px;
    cursor: pointer;
    text-align: right;
    padding-right: 10px;
    line-height: 30px;
  }
  .signOut-icon {
    color: $mainColor;
    width: 50px;
    height: 30px;
    text-align: right;
    background-color: $background1;
    border-top: 1px solid $borderColor1;
    border-left: 1px solid $borderColor1;
    border-right: 1px solid $borderColor1;
    z-index: 99;
    padding-right: 10px;
  }
  .signOutHover {
    position: absolute;
    right: 10px;
    top: 20px;
    height: 100px;
    width: 260px;
    z-index: 9;
    .list-button {
      display: flex;
      flex-wrap: wrap;
      position: absolute;
      right: 0;
      top: 29px;
      background-color: $background1;
      font-size: 20px;
      text-align: center;
      border: 1px solid $borderColor1;
      line-height: 30px;
      color: $mainColor;
      cursor: pointer;
      padding: 20px;
    }
    .list-button-item {
      padding: 0 5px;
      text-decoration: underline;
    }
  }
  .form {
    width: 500px;
    margin: auto;
    .form-title {
      font-size: 30px;
      color: $mainColor;
      text-align: left;
      margin-top: 150px;
      margin-bottom: 50px;
    }
    .form-item {
      display: flex;
      flex-direction: column;
      .el-input__inner {
        height: 48px;
      }

      .el-checkbox__label {
        line-height: 36px;
        color: #132569;
      }
    }
    .bond-radio {
      text-align: left;
    }
    .form-button {
      display: flex;
      justify-content: space-between;
      .send {
        width: 250px;
      }
    }
  }
}
</style>